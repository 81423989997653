import React from 'react'
import { FormattedDate } from 'react-intl'
import { useHistory, useLocation, Link } from 'react-router-dom'

import {
  Alert, Button,
  Card, CardHeader, CardBody, CardFooter,
  Table, Form, FormGroup,
  Row, Col,
  ListGroup, ListGroupItem,
  Input,
  NavLink,
  Spinner,
  Modal, ModalBody, ModalFooter,
} from 'reactstrap'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { AppContext } from 'contexts/AppContext'

import './InterventionNew.scss'

import CustomSelect from 'components/CustomSelect'
import TH from 'components/TH'
import Pagination from 'components/Pagination'
import { T, TAutocomplete, TButton, TLabel, TInput, TCustomInput, TAlert, ErrAlert } from 'components/TComponents'
import { useFormatMessage } from 'hooks/intl.hooks'

import { debounce } from 'util/Utils'

import ItvcodeSelector from 'components/ItvcodeSelector'
import { Fragment } from 'react'

import ProtectedComponent from 'components/ProtectedComponent'

const InterventionNew = () => {
  const { api, constants, user, subsidiary } = React.useContext(AppContext)
  const formatMessage = useFormatMessage()

  const location = useLocation()
  const history = useHistory()

  const [interventionCreating, setInterventionCreating] = React.useState(false)
  const [interventionCreateError, setInterventionCreateError] = React.useState()
  const [equipmentError, setEquipmentError] = React.useState()

  const [equipmentsSearchInput, setEquipmentsSearchInput] = React.useState()

  const [addresses, setAddresses] = React.useState()
  const [addressesLoading, setAddressesLoading] = React.useState(true)
  const [addressesError, setAddressesError] = React.useState()
  const [selectedAddress, setSelectedAddress] = React.useState('')

  const [equipments, setEquipments] = React.useState()
  const [equipmentAlert, setEquipmentAlert] = React.useState()
  const [equipmentsLoading, setEquipmentsLoading] = React.useState(true)
  const [equipmentsError, setEquipmentsError] = React.useState()

  const [itvcodes, setItvcodes] = React.useState([])
  const [itvcodesLoading, setItvcodesLoading] = React.useState(true)
  const [itvcodesError, setItvcodesError] = React.useState()

  const [equipmentAdded, setEquipmentAdded] = React.useState()
  const [itvcodesBase, setItvcodesBase] = React.useState()
  const [difficultyId, setDifficultyId] = React.useState(1)
  const [qualifId, setQualifId] = React.useState(0)
  const [locationId, setLocationId] = React.useState(0)
  const [information, setInformation] = React.useState('')
  const [claimNumber, setClaimNumber] = React.useState('')

  const [addressLabel, setAddressLabel] = React.useState('')
  const [addressComplement, setAddressComplement] = React.useState('')
  const [addressFull, setAddressFull] = React.useState('')
  const [mapId, setMapId] = React.useState('')
  const [autocomplete, setAutocomplete] = React.useState('')
  const [ticketId, setTicketId] = React.useState()

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phoneNumber, setPhoneNumber] = React.useState('')

  const [businessId, setBusinessId] = React.useState()
  const [businessQualif, setBusinessQualif] = React.useState([])
  const [businessQualifError, setBusinessQualifError] = React.useState('')
  const [businessQualifLoading, setBusinessQualifLoading] = React.useState(true)

  const [duplicateInterventionId, setDuplicateInterventionId] = React.useState()

  const [interventionError, setInterventionError] = React.useState()

  const [durationEstimated, setDurationEstimated] = React.useState(0)
  const [durationPlanned, setDurationPlanned] = React.useState(0)

  const [itvHourSelected, setItvHourSelected] = React.useState(0)
  const [itvMinuteSelected, setItvMinuteSelected] = React.useState(0)

  const itvHours = Array(9).fill().map((_, i) => i)
  const itvMinutes = Array(12).fill().map((_, i) => (i * 5))

  const [createdInterventionTemp, setCreatedInterventionTemp] = React.useState()
  const [interventionCreatingVeloptim, setInterventionCreatingVeloptim] = React.useState(false)


  // EQUIPMENTS MANAGMENT
  const getEquipments = React.useCallback(params => {
    return api.get('/equipments/list', undefined, {
      filter: equipments ? equipments.filters.filter : '',
      businessId: equipments && equipments.filters.businessId.selected
        ? equipments.filters.businessId.selected.value : undefined,
      categoryId: equipments && equipments.filters.categoryId.selected
        ? equipments.filters.categoryId.selected.value : undefined,
      familyId: equipments && equipments.filters.familyId.selected
        ? equipments.filters.familyId.selected.value : undefined,
      statusId: equipments && equipments.filters.statusId.selected
        ? equipments.filters.statusId.selected[0].value : undefined,
      'order[column]': equipments ? equipments.order.column : undefined,
      'order[dir]': equipments ? equipments.order.dir : undefined,
      p: equipments ? equipments.currentPage : 1,
      ipp: 3,
      ...params
    })
      .then(equipments => setEquipments(equipments))
      .catch(error => setEquipmentsError(error))
      .then(() => setEquipmentsLoading(false))
  }, [api, equipments])

  // TECHADDRESS
  const addressHandleChange = React.useCallback(e => {
    const addressId = e ? e.addressId : undefined

    const addressSelected = addressId
      ? addresses.find(address => String(address.addressId) === String(addressId))
      : undefined

    setSelectedAddress(addressId)
    setAddressLabel(addressSelected ? addressSelected.addressLabel : '')
    setAddressComplement(addressSelected ? addressSelected.addressComplement : '')
    setAddressFull(addressSelected ? addressSelected.addressFull : '')
    setMapId(addressSelected ? addressSelected.mapId : '')
  }, [addresses])

  const handleChangeAddressFull = React.useCallback(e => {
    e.persist()
    setAddressFull(e.target.value)
    api.get('/maps/autocomplete', undefined, {
      input: e.target.value,
      ...(autocomplete && autocomplete.session_token && { session_token: autocomplete.session_token })
    })
      .then(response => {
        setAutocomplete(response)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleAutocomplete = React.useCallback(e => {
    setAddressFull(e)
    api.get('/maps/placedetails', undefined, {
      place_id: autocomplete.results.find(r => r.addressFull === e).place_id,
      session_token: autocomplete.session_token,
      addressFull: e
    })
      .then(response => {
        setMapId(response.mapId)
      })
      .catch(error => console.log(error))
  }, [api, autocomplete])

  const handleItvHoursChange = React.useCallback(({ target: { value } }) => {
    setDurationPlanned(itvMinuteSelected + Number(value) * 60)
  }, [itvMinuteSelected])

  const handleItvMinutesChange = React.useCallback(({ target: { value } }) => {
    setDurationPlanned(Number(value) + itvHourSelected * 60)
  }, [itvHourSelected])

  const hasCodeReason = React.useCallback(itvcode => {
    let hasReason = false
    if (qualifId) {
      const qualifKey = constants.interventionQualif.find(qualif => qualif.value == qualifId) && constants.interventionQualif.find(qualif => qualif.value == qualifId).key
      if (itvcode.reasonListItvDisplay === 1 || (itvcode.reasonListItvDisplay === -1 && itvcode.enabledQualifReasonPart && itvcode.enabledQualifReasonPart.includes(qualifKey))) {
        hasReason = true
      } else {
        const code = itvcode.reason && itvcode.reason.length > 0 && itvcode.reason.find(r => r.businessId === equipments.filters.businessId.selected.value)
        if (code && (code.reasonListItvDisplay === 1 || (code.reasonListItvDisplay === -1 && code.enabledQualifReasonPart && code.enabledQualifReasonPart.includes(qualifKey)))) {
          hasReason = true
        }
      }
    }
    return hasReason
  }, [constants, equipments, qualifId])

  const createDuplicateIntervention = React.useCallback((e) => {
    e.preventDefault()
    const urlParams = new URLSearchParams(location.search)
    setInterventionCreating(true)
    setInterventionCreateError()
    return api.post('/interventions/newDuplicated', {
      body: JSON.stringify({
        itvcodes: itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          wear: itvcode.wear,
          ...(itvcode.reasonId && hasCodeReason(itvcode) && { reasonId: itvcode.reasonId })
        })),
        noQuality: parseInt(urlParams.get('noQuality')),
        equipmentId: equipmentAdded ? equipmentAdded.equipmentId : undefined,
        businessId,
        durationPlanned: durationPlanned || durationEstimated,
        difficultyId, qualifId, locationId,
        information: information || '',
        addressLabel, addressComplement, claimNumber, mapId,
        firstName, lastName, email, phoneNumber,
        duplicateInterventionId
      })
    })
      .then(response => {
        setInterventionCreating(false)
        return response
      })
  }, [addressComplement, addressLabel, api, businessId, claimNumber, difficultyId, duplicateInterventionId, durationEstimated, durationPlanned, email, equipmentAdded, firstName, hasCodeReason, information, itvcodes, lastName, location.search, locationId, mapId, phoneNumber, qualifId])

  const createIntervention = React.useCallback((e) => {
    e.preventDefault()
    const urlParams = new URLSearchParams(location.search)
    setInterventionCreating(true)
    setInterventionCreateError()
    return api.post('/interventions/new', {
      body: JSON.stringify({
        itvcodes: itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
          wear: itvcode.wear,
          ...(itvcode.reasonId && hasCodeReason(itvcode) && { reasonId: itvcode.reasonId })
        })),
        equipmentId: equipmentAdded ? equipmentAdded.equipmentId : undefined,
        businessId: equipments.filters.businessId.selected.value,
        durationPlanned: durationPlanned || durationEstimated,
        difficultyId, qualifId, locationId,
        information: information || '',
        ...((urlParams.get('ticketId') || ticketId) && { ticketId: parseInt(urlParams.get('ticketId')) || ticketId }),
        addressLabel, addressComplement, claimNumber, mapId,
        firstName, lastName, email, phoneNumber,
      })
    })
      .then(response => {
        setInterventionCreating(false)
        return response
      })
  }, [addressComplement, addressLabel, api, claimNumber, difficultyId, durationEstimated, durationPlanned, email, equipmentAdded, equipments, firstName, hasCodeReason, information, itvcodes, lastName, location, locationId, mapId, phoneNumber, qualifId, ticketId])

  // INTERVENTION
  const create = React.useCallback(e => {
    e.preventDefault()

    const urlParams = new URLSearchParams(location.search)

    if (urlParams.get('noQuality') || duplicateInterventionId) {
      return createDuplicateIntervention(e)
    } else {
      return createIntervention(e)
    }
  }, [location, duplicateInterventionId, createDuplicateIntervention, createIntervention])

  const createAndReturn = React.useCallback(e => {
    setInterventionCreateError()
    create(e)
      .then(() => history.push({
        pathname: '/interventions',
        search: location.state ? location.state.interventions : undefined
      }))
      .catch(error => setInterventionCreateError(error))
      .then(() => setInterventionCreating(false))
  }, [create, history, location])

  const createAndPlan = React.useCallback(e => {
    setInterventionCreateError()
    create(e)
      .then(intervention => history.push(`/interventions/plan/${intervention.interventionId}`))
      .catch(error => setInterventionCreateError(error))
      .then(() => setInterventionCreating(false))
  }, [create, history])

  const createAndPlanVeloptim = React.useCallback(e => {
    setInterventionCreateError()
    setInterventionCreatingVeloptim(true)
    create(e)
      .then(intervention => setCreatedInterventionTemp(intervention))
      .catch(error => {
        setInterventionCreateError(error)
        setInterventionCreatingVeloptim(false)
        setInterventionCreating(false)
      })
  }, [create])

  const createAndStart = React.useCallback(e => {
    setInterventionCreateError()
    create(e)
      .then(intervention => history.push(`/interventions/start/${intervention.interventionId}`))
      .catch(error => setInterventionCreateError(error))
      .then(() => setInterventionCreating(false))
  }, [create, history])

  React.useEffect(() => {
    if (createdInterventionTemp) {
      setInterventionCreatingVeloptim(false)
      setInterventionCreating(false)
      history.push({
        pathname: `/interventions/${createdInterventionTemp.interventionId}`,
        search: location.state ? location.state.interventions : undefined,
        veloptimModal: true
      })
      setCreatedInterventionTemp()
    }
  }, [history, location, createdInterventionTemp])

  const handleTransform = React.useCallback(e => {
    setInterventionCreateError()
    create(e)
      .then(response => {
        const cancelationReason = formatMessage({ id: `/interventions/transformFromSwap`, raw: true })
        api.del('/interventions/details', undefined, { interventionId: response.interventionId, cancelationReason })
          .catch(error => setInterventionCreateError(error))
          .then(response => {
            setInterventionCreating(false)
            history.push(`/swaps/new?interventionId=${response.interventionId}`)
          })
      })
      .catch(error => setInterventionCreateError(error))
      .then(() => setInterventionCreating(false))
  }, [api, history, create, formatMessage])

  const getEquipmentsDebounced = React.useMemo(() =>
    debounce(params => getEquipments(params), 250)
  , [getEquipments])

  const equipmentsHandleSearchInput = React.useCallback(e => {
    setEquipmentsSearchInput(e.target.value)
    getEquipmentsDebounced({ filter: e.target.value, p: 1 })
  }, [getEquipmentsDebounced])

  const equipmentsOrder = React.useCallback((e, column) => {
    e.preventDefault()

    getEquipments({
      'order[column]': column,
      'order[dir]': equipments.order.column === column && equipments.order.dir === 'asc' ? 'desc' : 'asc',
    })
  }, [getEquipments, equipments])

  const addEquipment = React.useCallback(equipment => {
    api.get('/equipments/usebyItvSwap', undefined, {
      equipmentId: equipment.equipmentId,
      businessId: equipment.businessId
    })
      .then(() => {
        setEquipmentError()
        api.get('/itvcodes/list', undefined, {
          ipp: -1,
          businessId: equipment.businessId,
          familyId: equipment.familyId,
          categoryId: equipment.categoryId
        })
          .then(response => setItvcodesBase(response))
          .then(() => setEquipmentAdded(equipment))
          .catch(error => setItvcodesError(error))
          .then(() => setItvcodesLoading(false))
      })
      .catch(error => setEquipmentError(error))

    api.get('/equipments/alerts', undefined, {
      equipmentId: equipment.equipmentId,
    })
      .then(response => setEquipmentAlert(response))
      .catch(error => setEquipmentError(error))
  }, [api])

  const removeEquipment = React.useCallback(() => {
    setItvcodes([])
    setEquipmentAdded()
  }, [])

  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search)

    if (urlParams.get('ticketId')) {
      api.get('/desk/details', undefined, { ticketId: urlParams.get('ticketId') })
        .then(ticket =>
          api.get('/equipments/list', undefined, { filter: ticket.equipmentIdentifier, ipp: 3 })
            .then(equipments => {
              setEquipments(equipments)

              setEquipmentsSearchInput(equipments.filters.filter)
              setClaimNumber(ticket.claimNumber)
              setInformation(ticket.issueDescription)
              setAddressLabel(ticket.addressLabel)
              setAddressFull(ticket.addressFull)

              setMapId(ticket.mapId)
              setAddressComplement(ticket.addressComplement)

              setFirstName(ticket.firstName)
              setLastName(ticket.lastName)
              setEmail(ticket.email)
              setPhoneNumber(ticket.phoneNumber)
            })
            .catch(error => setEquipmentsError(error))
            .then(() => setEquipmentsLoading(false))
        )
        .catch(() =>
          api.get('/equipments/list', undefined, { ipp: 3 })
            .then(equipments => setEquipments(equipments))
            .catch(error => setEquipmentsError(error))
            .then(() => setEquipmentsLoading(false))
        )
    } else if (urlParams.get('interventionId')) {
      // DUPLICATE CASE
      api.get('/interventions/details', undefined, { interventionId: urlParams.get('interventionId') })
        // add quality here
        .then(response => {
          setEquipmentAdded(response.equipment)
          setAddressComplement(response.addressComplement)
          setAddressLabel(response.addressLabel)
          setAddressFull(response.addressFull)
          setMapId(response.mapId)
          setItvcodes(response.itvcodes)
          setDifficultyId(response.difficultyId)
          setQualifId(response.qualifId)
          setLocationId(response.locationId)
          setInformation(response.information)
          setTicketId(response.deskTicketId)
          setDuplicateInterventionId(response.interventionId)
          setClaimNumber(response.claimNumber)
          setFirstName(response.firstName)
          setLastName(response.lastName)
          setEmail(response.email)
          setPhoneNumber(response.phoneNumber)

          api.get('/equipments/list', undefined, { 
            ipp: 3,
            businessId: response.businessId,
            familyId: response.equipment.familyId,
            categoryId: response.equipment.categoryId
          })
            .then(equipments => setEquipments(equipments))
            .catch(error => setEquipmentsError(error))
            .then(() => setEquipmentsLoading(false))

          return api.get('/itvcodes/list', undefined, {
            ipp: -1,
            businessId: response.businessId,
            familyId: response.equipment.familyId,
            categoryId: response.equipment.categoryId
          })
            .then(response => {
              setItvcodesBase(response)
            })
            .catch(response => setItvcodesError(response))
            .then(() => setItvcodesLoading(false))
        })
        .catch(response => setInterventionError(response))
    } else if (urlParams.get('equipmentId')) {
      api.get('/equipments/identity', undefined, { equipmentId: urlParams.get('equipmentId') })
        .then(equipment => addEquipment({ equipmentId: urlParams.get('equipmentId'), ...equipment }))
        .catch(() =>
          api.get('/equipments/list', undefined, { ipp: 3 })
            .then(equipments => setEquipments(equipments))
            .catch(error => setEquipmentsError(error))
            .then(() => setEquipmentsLoading(false))
        )
    } else if (urlParams.get('swapId')) {
      api.get('/swaps/details', undefined, { swapId: urlParams.get('swapId') })
        .then(swap => {
          api.get('/equipments/list', undefined, { filter: swap.equipmentIdentifier, ipp: 3 })
            .then(equipments => {
              setEquipments(equipments)
              api.get('/itvcodes/list', undefined, {
                ipp: -1,
                businessId: swap.businessId,
                familyId: swap.equipmentBefore.familyId,
                categoryId: swap.equipmentBefore.categoryId
              })
                .then(response => {
                  setItvcodesBase(response)
                  setItvcodes(response.result.filter(r => swap.itvcodes.map(i => i.itvcodeId).includes(r.itvcodeId)).map(i => ({ ...i, quantity: swap.itvcodes.find(ii => ii.itvcodeId === i.itvcodeId).quantity, wear: swap.itvcodes.find(ii => ii.itvcodeId === i.itvcodeId).wear })))
                })
                .then(() => setEquipmentAdded(swap.equipmentBefore))
                .catch(error => setItvcodesError(error))
                .then(() => {
                  setItvcodesLoading(false)
                  setEquipmentsLoading(false)
                })

              setDurationEstimated(swap.durationEstimated)
              setDurationPlanned(swap.durationPlanned)

              setClaimNumber(swap.claimNumber)
              setInformation(swap.information)

              setLocationId(swap.locationId)

              setMapId(swap.mapId)
              setAddressComplement(swap.addressComplement)
              setAddressFull(swap.addressFull)
              setAddressLabel(swap.addressLabel)

              setFirstName(swap.firstName)
              setLastName(swap.lastName)
              setEmail(swap.email)
              setPhoneNumber(swap.phoneNumber)
            })
            .catch(error => setEquipmentsError(error))
        })
        .catch(() =>
          api.get('/equipments/list', undefined, { ipp: 3 })
            .then(equipments => setEquipments(equipments))
            .catch(error => setEquipmentsError(error))
        )
    } else {
      api.get('/equipments/list', undefined, { ipp: 3 })
        .then(equipments => setEquipments(equipments))
        .catch(error => setEquipmentsError(error))
        .then(() => setEquipmentsLoading(false))
    }
  }, [addEquipment, api, location, setEquipmentsSearchInput])

  React.useEffect(() => {
    if (equipments && equipments.filters.businessId.selected && businessId !== equipments.filters.businessId.selected.value) {
      setBusinessId(equipments.filters.businessId.selected.value)
    }
  }, [businessId, equipments])

  React.useEffect(() => {
    if (businessId !== undefined) {
      setAddressesLoading(true)
      setBusinessQualifLoading(true)
      api.get('/interventions/addresses', undefined, { businessId })
        .then(response => setAddresses(response))
        .catch(response => setAddressesError(response))
        .then(() => setAddressesLoading(false))
      api.get('/interventions/qualif', undefined, { businessId })
        .then(response => setBusinessQualif(response))
        .catch(response => setBusinessQualifError(response))
        .then(() => setBusinessQualifLoading(false))
    }
  }, [api, businessId, setBusinessQualifError, setBusinessQualifLoading])

  React.useEffect(() => {
    if (itvcodes.length > 0) {
      const body = {
        itvcodes: itvcodes.map(itvcode => ({
          itvcodeId: itvcode.itvcodeId,
          quantity: itvcode.quantity,
        }))
      }
      api.post('/interventions/timeItvcodes', { body: JSON.stringify(body) })
        .then(response => setDurationEstimated(response.durationEstimated))
    } else {
      setDurationEstimated(0)
    }
  }, [api, itvcodes])

  React.useEffect(() => {
    setItvHourSelected(Math.trunc((durationPlanned || durationEstimated) / 60))
    setItvMinuteSelected((durationPlanned || durationEstimated) % 60)
  }, [durationPlanned, durationEstimated])

  const _renderEquipmentSelector = React.useCallback(() => (
    <Card className="mb-3">
      <CardHeader>
        <Form onSubmit={e => e.preventDefault()}>
          <Row form>
            <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="equipmentsSearchInput" id="equipmentsSearchInputLabel" className="" />
                <TInput
                  name="equipmentsSearchInput"
                  type="text"
                  placeholder="equipmentsSearchInputPlaceholder"
                  value={equipmentsSearchInput}
                  onChange={e => equipmentsHandleSearchInput(e)} />
              </FormGroup>
            </Col>
            {equipments.filters && equipments.filters.businessId && equipments.filters.businessId.values && <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="businessId" id="businessLabel" className="" />
                <CustomSelect
                  inputId="businessId"
                  name="businessId"
                  onChange={e => getEquipments({ businessId: e && e.value })}
                  isDisabled={equipmentsLoading || equipmentAdded}
                  options={equipments.filters.businessId.values}
                  value={equipments.filters.businessId.selected ? equipments.filters.businessId.selected : ''}
                />
              </FormGroup>
            </Col>}
            {equipments.filters && equipments.filters.categoryId && equipments.filters.categoryId.values && <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="categoryId" id="categoryLabel" className="" />
                <CustomSelect
                  inputId="categoryId"
                  name="categoryId"
                  isClearable
                  options={equipments.filters.categoryId.values}
                  onChange={e => getEquipments({ categoryId: e && e.value })}
                  placeholder={<T id="categoryPlaceholder" />}
                  isDisabled={equipmentsLoading}
                  value={equipments.filters.categoryId.selected || ''} />
              </FormGroup>
            </Col>}
            {equipments.filters && equipments.filters.familyId && equipments.filters.familyId.values && <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="familyId" id="familyLabel" className="" />
                <CustomSelect
                  inputId="familyId"
                  name="familyId"
                  isClearable
                  options={equipments.filters.familyId.values}
                  onChange={e => getEquipments({ familyId: e && e.value })}
                  placeholder={<T id="familyPlaceholder" />}
                  isDisabled={equipmentsLoading}
                  value={equipments.filters.familyId.selected || ''} />
              </FormGroup>
            </Col>}
            {equipments.filters && equipments.filters.statusId && equipments.filters.statusId.values && <Col sm="6" md="3">
              <FormGroup tag="fieldset">
                <TLabel for="statusId" id="statusLabel" className="" />
                <CustomSelect
                  inputId="statusId"
                  name="statusId"
                  isClearable
                  options={equipments.filters.statusId.values}
                  onChange={e => getEquipments({ statusId: e && e.value })}
                  placeholder={<T id="statusPlaceholder" />}
                  isDisabled={equipmentsLoading}
                  value={equipments.filters.statusId.selected || ''}
                  getOptionLabel={option => <T id={`equipmentStatus.${option.label}`} raw />} />
              </FormGroup>
            </Col>}
          </Row>
        </Form>
      </CardHeader>
      <Table responsive striped hover className="mb-0 mt-2">
        <thead>
          <tr><TH colSpan="7" className={['border-top-0']}
            colName="count"
            colValues={{ total: equipments.total }} />
          </tr>
        </thead>
        <thead>
          <tr>
            <TH colName="equipmentIdentifier" order={equipments.order} sort={equipmentsOrder} />
            <TH colName="equipmentLastItv" order={equipments.order} sort={equipmentsOrder} />
            <TH colName="categoryName" order={equipments.order} sort={equipmentsOrder} />
            <TH colName="familyName" order={equipments.order} sort={equipmentsOrder} />
            <TH colName="dateCreated" order={equipments.order} sort={equipmentsOrder} />
            <TH colName="equipmentStatus" />
            <TH />
          </tr>
        </thead>
        <tbody style={{ transform: `rotate(0)` }}>
          {equipmentsLoading && <tr><td><div className="floating-spinner">
            <style dangerouslySetInnerHTML={{
              __html: `
                .floating-spinner {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }

                .floating-spinner:before {
                  content: '';
                  top: 0;
                  left: 0;
                  position: absolute;
                  background: black;
                  width: 100%;
                  height: 100%;
                  opacity: .1;
                }` }}>
            </style>
            <Spinner className="" color="primary" />
          </div></td></tr>}
          {equipments.result.length > 0 && equipments.result.map(equipment => {
            return <tr key={equipment.equipmentId}>
              <td>{equipment.equipmentIdentifier}</td>
              <td>{equipment.dateIntervention}</td>
              <td>{equipment.categoryName}</td>
              <td>{equipment.familyName}</td>
              <td><FormattedDate value={new Date(equipment.dateCreated)} /></td>
              <td><T id={`equipmentStatus.${equipment.statusValue}`} raw /></td>
              <td>
                <Button className="ml-auto"
                  color={'secondary'}
                  disabled={false}
                  onClick={() => addEquipment(equipment)}>
                  <T id="addEquipment" />
                </Button>
              </td>
            </tr>
          })}
        </tbody>
        <tfoot>
          <tr><td colSpan="100" className="pb-0">
            <Pagination
              totalPage={equipments.totalPage}
              currentPage={equipments.currentPage}
              onPageClick={p => getEquipments({ p })} /></td></tr>
        </tfoot>
      </Table>
      {equipmentError && <Alert className="mt-4 mb-0" color="danger"><T id={`error.${equipmentError.code}`} raw /></Alert>}
    </Card >
  ), [addEquipment, equipments, equipmentAdded, equipmentsHandleSearchInput, equipmentsLoading, equipmentsOrder, equipmentsSearchInput, getEquipments, equipmentError])

  const popupError = equipmentError && ['alreadyMaintenanceEquipment'].includes(equipmentError.code) ? equipmentError : undefined

  return (
    <>
      <div className="container-fluid InterventionNew">
        <NavLink tag={Link} to={{
          pathname: '/interventions',
          search: location.state ? location.state.interventions : undefined
        }}>
          <T id="returnToList" />
        </NavLink>
        {interventionError && <TAlert color="warning" id="duplicateInterventionError" />}
        <Card className="mb-2">
          <CardBody>
            {!equipmentAdded
              ? <><TLabel id="equipmentAdd" />
                {!equipmentsError && equipments && _renderEquipmentSelector()}
                {equipmentsError && <ErrAlert error={equipmentsError} />}
              </>
              : <><TLabel id="equipmentList" />
                <ListGroup className="mb-3">
                  <ListGroupItem className="d-flex align-items-center">
                    {equipmentAdded.equipmentIdentifier}
                    <TButton className="ml-auto pt-2 pb-2 mt-0"
                      onClick={() => removeEquipment()}
                      id="removeEquipment" />
                  </ListGroupItem>
                </ListGroup>
              </>
            }

            {equipmentAlert && (
              <div className="mb-3 pt-2">
                {!!equipmentAlert.nbInfo && <TAlert color="info" id="nbInfo" values={{ value: equipmentAlert.nbInfo }} />}
                {!!equipmentAlert.nbMinor && <TAlert color="warning" id="nbMinor" values={{ value: equipmentAlert.nbMinor }} />}
                {!!equipmentAlert.nbMajor && <TAlert color="danger" id="nbMajor" values={{ value: equipmentAlert.nbMajor }} />}
                {(!!equipmentAlert.nbInfo || !!equipmentAlert.nbMinor || !!equipmentAlert.nbMajor) && equipmentAdded && (
                  <TButton id="goToEquipmentLog"
                    tag={Link}
                    to={{
                      pathname: `/equipments/${equipmentAdded.equipmentId}/logs`,
                      state: location.state
                    }}
                    color="primary" />
                )}
              </div>
            )}

            {!equipmentAdded && <TAlert color="warning" id="noEquipmentAdded" />}

            <FormGroup tag="fieldset">
              <TLabel id="qualifId" for="qualifId" />
              <div>
                {businessQualifLoading && <Spinner className="" color="primary" />}
                {!businessQualifLoading && businessQualifError && <ErrAlert error={businessQualifError} />}
                {!businessQualifLoading && !businessQualifError && (
                  <>
                    {constants.interventionQualif.map(qualif =>
                      <Fragment key={qualif.key}>
                        {businessQualif.find(bq => bq.key === qualif.key).enabled && (
                          <FormGroup key={qualif.key} check inline>
                            <TCustomInput
                              id={`qualifId-${qualif.key}`}
                              type="radio"
                              name="qualifId"
                              label={`qualif.${qualif.key}`}
                              disabled={interventionCreating}
                              checked={qualifId == qualif.value}
                              value={qualif.value}
                              onChange={e => setQualifId(e.target.value)} />
                          </FormGroup>
                        )}
                      </Fragment>
                    )}
                  </>
                )}
              </div>
            </FormGroup>

            <TLabel id="itvcodeList" />

            {!equipmentAdded && <TAlert color="warning" id="missingEquipmentForItvcode" />}

            {itvcodesError ? <ErrAlert error={itvcodesError} /> : equipments && itvcodesBase && constants &&
              <ItvcodeSelector
                hasBusiness={equipments.filters.businessId.selected}
                loading={equipmentsLoading || itvcodesLoading}
                itvcodesAdded={itvcodes}
                itvcodes={itvcodesBase.result}
                reason
                isIntervention
                qualif={constants.interventionQualif.find(qualif => qualif.value == qualifId) && constants.interventionQualif.find(qualif => qualif.value == qualifId).key}
                onChange={itvcodes => setItvcodes(itvcodes)} />}
            <>
              <TLabel id="durations" />
              <Card className="mb-4">
                <CardBody className="pb-3">
                  <div className="row mb-2">
                    {/* Display /60 hour */}
                    <TLabel
                      id="durationEstimated"
                      values={{
                        hours: Math.floor(durationEstimated / 60),
                        minutes: durationEstimated % 60,
                      }}
                      className="col mb-2" />
                  </div>
                  {!!durationEstimated && (
                    <>
                      <TLabel id="durationPlanned" className="col mb-2 pl-0" />
                      <Row>
                        <Col>
                          <FormGroup tag="fieldset">
                            <TLabel for="selectHour" id="selectHour" />
                            <CustomSelect
                              inputId="selectHour"
                              name="selectHour"
                              options={itvHours}
                              placeholder={"0"}
                              onChange={e => handleItvHoursChange({ target: { value: e } })}
                              value={itvHours.filter(h => h == itvHourSelected)}
                              getOptionValue={option => option}
                              getOptionLabel={option => option}
                              isDisabled={interventionCreating} />
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup tag="fieldset">
                            <TLabel for="selectMinute" id="selectMinute" />
                            <CustomSelect
                              inputId="selectMinute"
                              name="selectMinute"
                              options={itvMinutes}
                              onChange={e => handleItvMinutesChange({ target: { value: e } })}
                              placeholder={"0"}
                              value={itvMinutes.filter(h => h == itvMinuteSelected)}
                              getOptionValue={option => option}
                              getOptionLabel={option => option}
                              isDisabled={interventionCreating} />
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </>

            <FormGroup tag="fieldset">
              <TLabel id="difficultyId" for="difficultyId" />
              <div className={`difficultyInput ${constants.difficultyLevels.find(diff => String(diff.value) === String(difficultyId)).key}`}>
                <CustomSelect
                  inputId="difficultyId"
                  name="difficultyId"
                  options={constants.difficultyLevels}
                  onChange={e => setDifficultyId(e && e.value)}
                  value={constants.difficultyLevels.filter(d => d.value === difficultyId)}
                  isDisabled={interventionCreating}
                  getOptionLabel={option => <T id={`difficulty.${option.key}`} raw />}
                  getOptionValue={option => option.value} />
              </div>
            </FormGroup>

            <FormGroup tag="fieldset">
              <TLabel id="claimNumber" for="claimNumber" />
              <Input id="claimNumber"
                type="text"
                name="claimNumber"
                disabled={interventionCreating}
                value={claimNumber}
                onChange={e => setClaimNumber(e.target.value)} />
            </FormGroup>

            <FormGroup tag="fieldset">
              <TLabel id="information" for="information" />
              <Input id="information"
                type="textarea"
                name="information"
                disabled={interventionCreating}
                value={information}
                onChange={e => setInformation(e.target.value)} />
            </FormGroup>

            <TLabel id="addressTitle" className="mb-1" />
            <Card className="mb-3">
              <CardBody>
                <FormGroup tag="fieldset">
                  <TLabel for="addresses" className="mt-0" id="addresses.label" />
                  {addressesLoading && <Spinner className="" color="primary" />}
                  {!addressesLoading && addressesError && <ErrAlert error={addressesError} />}
                  {!addressesLoading && !addressesError && (
                    <CustomSelect
                      inputId="addresses"
                      name="addresses"
                      options={addresses}
                      onChange={addressHandleChange}
                      placeholder={<T id="addresses.manual" />}
                      value={addresses && addresses.filter(a => a.addressId === selectedAddress) || ''}
                      isClearable
                      isDisabled={interventionCreating}
                      getOptionLabel={option => `${option.addressLabel} – ${option.addressFull} ${option.addressComplement && '- ' + option.addressComplement}`}
                      getOptionValue={option => option.addressId} />
                  )}
                </FormGroup>

                <FormGroup tag="fieldset" className="flex-grow-1">
                  <TLabel for="addressLabel" className="my-0" id="addressLabel" />
                  <Input id="addressLabel"
                    className="w-100"
                    type="text"
                    name="addressLabel"
                    disabled={interventionCreating || selectedAddress}
                    value={addressLabel || ''}
                    onChange={e => setAddressLabel(e.target.value)} />
                </FormGroup>
                <FormGroup tag="fieldset" className="w-100">
                  <TLabel for="addressFull" className="mb-2" id="addressFull" />
                  <TAutocomplete id="addressFull"
                    className="w-100"
                    type="text"
                    name="addressFull"
                    disabled={interventionCreating || selectedAddress}
                    value={addressFull || ''}
                    placeholder="addressFullPlaceholder"
                    searchOnFocus={e => handleChangeAddressFull(e)}
                    onChange={e => handleChangeAddressFull(e)}
                    onAutocomplete={e => handleAutocomplete(e)}
                    options={
                      autocomplete && autocomplete.results.length > 0 && autocomplete.results.map(r => {
                        return (r.addressFull)
                      })} />
                </FormGroup>
                <FormGroup tag="fieldset" className="w-100 mb-0">
                  <TLabel for="addressComplement" className="justify-content-start mb-0" id="addressComplement" />
                  <TInput id="addressComplement"
                    className="w-100"
                    type="text"
                    name="addressComplement"
                    disabled={interventionCreating || selectedAddress}
                    value={addressComplement || ''}
                    placeholder="addressComplementPlaceholder"
                    onChange={e => setAddressComplement(e.target.value)} />
                </FormGroup>
              </CardBody>
            </Card>

            <FormGroup tag="fieldset">
              <TLabel id="locationId" for="locationId" />
              <div>
                {constants.interventionLocation.map(type => <FormGroup key={type.key}
                  check inline>
                  <TCustomInput
                    id={`locationId-${type.key}`}
                    type="radio"
                    name="locationId"
                    label={`locationId.${type.key}`}
                    disabled={interventionCreating}
                    checked={Number(locationId) == type.value}
                    value={type.value}
                    onChange={e => setLocationId(e.target.value)} />
                </FormGroup>)}
              </div>
            </FormGroup>

            <TLabel id="contactTitle" className="mb-1" />
            <Card>
              <CardBody>
                <div className="d-flex">
                  <FormGroup tag="fieldset" className=" w-100 flex-grow-1">
                    <TLabel for="firstName" className="my-0" id="firstName" />
                    <Input name="firstName"
                      type="text"
                      value={firstName}
                      disabled={interventionCreating}
                      onChange={e => setFirstName(e.target.value)} />
                  </FormGroup>
                  <FormGroup tag="fieldset" className="ml-3 w-100 flex-grow-1">
                    <TLabel for="lastName" className="my-0" id="lastName" />
                    <Input name="lastName"
                      type="text"
                      value={lastName}
                      disabled={interventionCreating}
                      onChange={e => setLastName(e.target.value)} />
                  </FormGroup>
                </div>
                <div className="d-flex">
                  <FormGroup tag="fieldset" className="w-100 flex-grow-1">
                    <TLabel for="email" className="my-0" id="email" />
                    <Input name="email"
                      type="text"
                      value={email}
                      disabled={interventionCreating}
                      onChange={e => setEmail(e.target.value)} />
                  </FormGroup>
                  <FormGroup tag="fieldset" className="ml-3 mb-0 w-100 flex-grow-1">
                    <TLabel id="phoneNumber" for="phoneNumber" className="my-0" />
                    <Input name="phoneNumber"
                      id="phoneNumber"
                      tag={PhoneInput}
                      defaultCountry="FR"
                      country="FR"
                      className="w-100 d-flex"
                      type="text"
                      value={phoneNumber}
                      disabled={interventionCreating}
                      onChange={value => setPhoneNumber(value)} />
                  </FormGroup>
                </div>
              </CardBody>
            </Card>

            {!popupError && interventionCreateError && <Alert className="mt-4 mb-0" color="danger"><T id={`error.${interventionCreateError.code}`} raw /></Alert>}

            {popupError && <Modal isOpen={Boolean(popupError.code)}
              fade={false}
              toggle={() => setEquipmentError()}>
              <T id={`error.${popupError.code}.${popupError.params.type}.body`} values={equipmentError.params} tagName={ModalBody} />
              <ModalFooter>
                <TButton id={`error.${popupError.code}.${popupError.params.type}.cancel`}
                  onClick={() => setEquipmentError()} />
                <TButton id={`error.${popupError.code}.${popupError.params.type}.confirm`}
                  tag={Link}
                  to={{
                    pathname: `/${popupError.params.type}/${popupError.params.id}`,
                    state: location.state
                  }}
                  className="ml-2 d-flex align-items-center" color="primary" />
              </ModalFooter>
            </Modal>}
          </CardBody>

          <CardFooter className="d-flex justify-content-between">
            <div>
              <ProtectedComponent rights={['interventions_transform-swaps']}>
                <TButton
                  className="mx-1"
                  onClick={e => handleTransform(e)}
                  id="transform" />
              </ProtectedComponent>
            </div>
            <div>
              <Button disabled={interventionCreating || interventionCreatingVeloptim} type="button" className="ml-2" color="primary" onClick={e => createAndReturn(e)}><T id="createAndReturn" /></Button>
              {((user.enableVeloptim && subsidiary.enableVeloptim) || user.email == 'email-admin@test.tld') ? (
                <>
                  <TButton disabled={interventionCreatingVeloptim} loading={interventionCreatingVeloptim} type="button" className="ml-2" color="primary" onClick={e => createAndPlanVeloptim(e)} id="createAndPlan" />
                  <Button disabled={interventionCreating || interventionCreatingVeloptim} type="button" className="ml-2" color="primary" onClick={e => createAndPlan(e)}><T id="createAndPlanWithoutVeloptim" /></Button>
                </>
              ) : (
                <Button disabled={interventionCreating || interventionCreatingVeloptim} type="button" className="ml-2" color="primary" onClick={e => createAndPlan(e)}><T id="createAndPlan" /></Button>
              )}
              <Button disabled={interventionCreating || interventionCreatingVeloptim} type="cancel" tag={Link}
                className="ml-2"
                to={{
                  pathname: '/interventions',
                  search: location.state ? location.state.interventions : undefined
                }}>
                <T id="cancel" />
              </Button>
              <ProtectedComponent rights={['interventions-end_web']}>
                <TButton disabled={interventionCreating || interventionCreatingVeloptim} type="button" className="ml-2" color="primary" onClick={e => createAndStart(e)} id="createAndStart" />
              </ProtectedComponent>
            </div >
          </CardFooter>
        </Card >
      </div >
    </>
  )
}

export default InterventionNew
